import React from "react"
import DynamicComponent from "../components/DynamicComponent"
import Layout from "../components/Layout"
import useStoryblok from "../utils/storyblok"
import { useStoryblokState, StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import { graphql } from "gatsby"
import { getActiveLanguage, parse } from "../utils/helper"
import Crumb from "../components/Crumb"
import LatestNews from "../components/LatestNews"

export default function BlogEntry({ pageContext, location, data }) {
  const story = useStoryblokState(pageContext.story)
  const layout = data?.layouts?.edges?.find(i => i.node?.lang === getActiveLanguage(location))
  const { get_in_touch_section, breadcrumbs, recommendation } = parse(layout?.node?.content) || {}
  
  return (
    <Layout location={location} slug={story?.slug} enableGetinTouch={get_in_touch_section} {...storyblokEditable(story.content)} >
      <div className="breadcrumb">
        <div className="container">
        <ul>
          {
            Array.isArray(breadcrumbs) && breadcrumbs.map((crumb, idx) => <Crumb blok={crumb} index={idx} />)
          }
        </ul>
        </div>
      </div>
      <StoryblokComponent blok={story.content} key={story._uid} />
      {
        Array.isArray(recommendation) && recommendation.map((blok, idx) => <LatestNews blok={blok} index={idx} />)
      }
    </Layout>
  )
}

export const query = graphql`
  query BlogLayoutQuery {
    layouts : allStoryblokEntry(filter: {field_component: {eq: "post_template"}}) {
      edges {
        node {
          id
            uuid
            name
            slug
            field_component
            full_slug
            content
            lang
        }
      }
    }
  }
`;